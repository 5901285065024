/**
 * @author RamaTulasi <rama.tulasi@sollishealth.com>
 */
import { useMsal } from '@azure/msal-react';
import React, { useEffect, useInsertionEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import ContactModal from "./ContactModal";
import MessageModal from "./MessageModal";
import BreadCrumb from '../../components/BreadCrumb';
import { storeMemberDetails } from '../../features/memberDetailsSlice';
import {
    getMemberDetails,
    GetUnReadLabResultCount,
    GetUnReadMessageCount,
    GetAllAppointments,
    getStateList,
    getLocationsList,
    GetAppointmentTypes
} from '../../services/RestApi';
import { resetAppointmentDetails } from '../../features/appointmentDetailsSlice';
import { storeUpcomingAppointments } from '../../features/appointmentSlice';
import moment from 'moment';
import Geocode from 'react-geocode';
import { API_KEY } from '../../utilities/Constants';
import {
    storeLocations,
    storeContactNumber,
    storeNearestLocation
} from '../../features/locationSlice';
import { useOnlineStatus } from '../../utilities/useOnlineStatus';
import { hideLoader, showLoader } from '../../utilities/Loader';
import { storeAppointmentTypesFL } from '../../features/appointmentTypesFLSlice';
import { checkVersionDiff } from '../../utilities/helper';
import { datadogRum } from '@datadog/browser-rum';
import { useImpersonation } from '../../components/impersonation-provider';
import { DashboardRouterButton as DocumentCenterButton } from 'features/document-center'
import { DashboardRouterButton as DiagnosticImagingCenterButton } from 'features/diagnostic-imaging'
import FooterCompliance from 'components/FooterCompliace';

function Home() {
    const pendingAppointments = useSelector(
        (state) => state.appointments.pendingAppointments
    );
    const upcomingAppointments = useSelector(
        (state) => state.appointments.upcomingAppointments
    );
    const _appointmentTypesFL = useSelector(
        (state) => state.appointmentTypesFL.appointmentTypesFL,
        shallowEqual
    );

    const _memberDetails = useSelector((state) => state.memberDetails);
    const isOnline = useOnlineStatus(); //network connection status
    const dispatch = useDispatch();
    const [memberDetails, setMemberDetails] = useState(_memberDetails);
    const [totalCount, setTotalCount] = useState();
    const [hasAppointments, setHasAppointments] = useState(false);
    const { accounts } = useMsal();
    const history = useHistory();
    const [currentLocation, setCurrentLocation] = useState(null);
    const [state, setState] = useState(null);
    const [stateList, setStateList] = useState(null);
    //const [contactNumber, setContactNumber] = useState();
    const [contactNumber, setContactNumber] = useState("929-209-2208");
    const [open, setOpen] = useState(false)
    const [openContacts, setOpenContacts] = useState(false)

    const [today, setDate] = React.useState(new Date());
    const [patientIds, setPatientIds] = useState([]);
    const [billCount, setBillCount] = useState();
    const [preferredState, setPreferredState] = useState();
    const [messagesCount, setMessagesCount] = useState();
    const [labResultCount, setLabResultCount] = useState();
    const [loading, setLoading] = useState(false);
    const [isAvailable, setIsAvailable] = useState(false);
    const [locationsList, setLocationsList] = useState([]);
    const [labResultCountLoading, setLabResultCountLoading] = useState(false);
    const { isImpersonating, user } = useImpersonation();

    const [upcomingAppointmentDate, setUpcomingAppointmentDate] = useState('');
    Geocode.setApiKey(API_KEY);
    Geocode.setLocationType('ROOFTOP');
    let timer;
    let greyOutAreas = false;


    /**
     *
     */

    const appointmenttype = () => {
        let path = `appointmenttype`;
        if (navigator.onLine) history.push(path);
    };
    const messages = () => {
        let path = `messages/1`;
        history.push(path);
    };
    const routeChange = () => {
        let path = `labresults`;
        history.push(path);
    };
    const billing = () => {
        let path = `billing`;
        if (navigator.onLine) history.push(path);
    };
    const SpecialistReferralList = () => {
        let path = `specialistreferrallist`;
        history.push(path);
    };

    const viewAppointments = () => {
        let path = `scheduleAppointments`;
        if (navigator.onLine) history.push(path);
    };

    // To get phone Number
    useEffect(() => {
        getStateList().then((res) => {
            checkVersionDiff(dispatch, res);
            
            let stateList = res.data.data.stateList.state;
            setStateList(res.data.data.stateList.state);
            getLocationsList().then((res) => {
                checkVersionDiff(dispatch, res);
                let departments = res.data.data;
                

                const preferredState = departments?.find(
                    (it) =>
                        it.departmentid ==
                        _memberDetails?.loginMember[0]
                            ?.memberPreferredlocationID
                ).state;
                setPreferredState(preferredState);
                if (navigator.geolocation) {
                    navigator.permissions
                        .query({ name: 'geolocation' })
                        .then(function (result) {
                            if (result.state === 'granted') {
                                
                                //If granted then you can directly call your function here
                                navigator.geolocation.getCurrentPosition(
                                    (pos) => {
                                        const coords = pos.coords;
                                        // 
                                        setCurrentLocation({
                                            lat: coords.latitude,
                                            lng: coords.longitude,
                                        });
                                        let userLatLong = {
                                            lat: coords.latitude,
                                            long: coords.longitude,
                                            locationAccess: true
                                        };
                                        dispatch(storeNearestLocation(userLatLong));
                                        Geocode.fromLatLng(
                                            coords.latitude,
                                            coords.longitude
                                        ).then(
                                            (response) => {
                                                // const address = response.results[0].formatted_address;
                                                let city, state, country;
                                                for (
                                                    let i = 0;
                                                    i <
                                                    response.results[0]
                                                        .address_components
                                                        .length;
                                                    i++
                                                ) {
                                                    for (
                                                        let j = 0;
                                                        j <
                                                        response.results[0]
                                                            .address_components[
                                                            i
                                                        ].types.length;
                                                        j++
                                                    ) {
                                                        switch (
                                                            response.results[0]
                                                                .address_components[
                                                                i
                                                            ].types[j]
                                                        ) {
                                                            case 'locality':
                                                                city =
                                                                    response
                                                                        .results[0]
                                                                        .address_components[
                                                                        i
                                                                    ].long_name;
                                                                break;
                                                            case 'administrative_area_level_1':
                                                                state =
                                                                    response
                                                                        .results[0]
                                                                        .address_components[
                                                                        i
                                                                    ].long_name;
                                                                break;
                                                            case 'country':
                                                                country =
                                                                    response
                                                                        .results[0]
                                                                        .address_components[
                                                                        i
                                                                    ].long_name;
                                                                break;
                                                        }
                                                    }
                                                }
                                                // 
                                                
                                                setState(state);
                                            },
                                            (error) => {
                                                console.error(error);
                                            }
                                        );
                                    }
                                );
                            } else if (result.state === 'prompt') {
                                
                                setState(preferredState);
                            } else if (result.state === 'denied') {
                                //If denied then you have to show instructions to enable location
                                setState(preferredState);
                            }
                            result.onchange = function () {
                                
                            };
                        });
                }
                // 
                // setState(preferredState);

                setLocationsList(departments);
                dispatch(storeLocations(departments));
                let _contactNumber =
                    stateList?.find(
                        (it) =>
                            it.stateAbbreviation == state ||
                            it.stateName == state
                    )?.contactConciergePhone ||
                    stateList?.find(
                        (it) => it.stateAbbreviation == preferredState
                    )?.contactConciergePhone;
                dispatch(storeContactNumber(_contactNumber));
                setContactNumber(_contactNumber);
            });
        });
    }, [isOnline]);

    /**
     * Getting the member details from the store and handover to the current state
     */

    useEffect(() => {
        setMemberDetails(_memberDetails);
    }, [_memberDetails]);

    useEffect(()=>{
        const user = {
            id: accounts[0].idTokenClaims?.oid,
            name: accounts[0].name,
            email: accounts[0].username,
        };
        console.log(user);
        datadogRum.setUser(user);
    }, []);

    /**
     * Getting memberdetails baseed on current username and sending data to store
     */
    useEffect(() => {
        showLoader();
        dispatch(resetAppointmentDetails());
        let data = {
            emailId: accounts[0]?.username,
        };
        //  let data = {
        // 	"emailId": 'memberportaluser1@gmail.com'
        // };
        if (!_memberDetails.loginMember.length) {
            getMemberDetails(data)
                .then((res) => {
                    checkVersionDiff(dispatch, res);
                    dispatch(storeMemberDetails(res.data?.data[0]));
                    setMemberDetails(res.data?.data[0]);
                    _getPatientIds(res.data?.data[0]);
                    setIsAvailable(true);
                })
                .catch((e) => {
                    setLoading(false)
                });
        } else {
            let md = JSON.parse(JSON.stringify(_memberDetails));
            md.loginMember[0].type = 'In-Clinic';
            dispatch(storeMemberDetails(md));
            setMemberDetails(_memberDetails);
            _getPatientIds(_memberDetails);
            setIsAvailable(true);
        }
    }, []);

    useEffect(() => {
        // !(_appointmentTypesFL && _appointmentTypesFL.length) &&
        GetAppointmentTypes({ id: 1 }).then((resp) => {
            checkVersionDiff(dispatch, resp);
            let temp = JSON.parse(JSON.stringify(_appointmentTypesFL));
            temp[1] =
                resp?.data?.data[0]?.appointmentTypeGroups[0]?.appointmentTypeGroup;
            dispatch(storeAppointmentTypesFL(temp));
        })
    }, []);

    /**
     *
     * @param {*} _members :Existing Members
     * Retreving the patient id's based on the members added in the multimember page
     */
    const _getPatientIds = (_members) => {
        let patientids = [],
            patientDetails = [];

        let members = _members;

        let addedMembers = [];

        patientids.push({
            patientid: members.loginMember[0].patientID,
            memberId: members.loginMember[0].memberID,
        });

        patientDetails[members.loginMember[0].patientID] = {
            memberUsername: members.loginMember[0].memberUsername,
        };

        members.dependents.forEach((dependent) => {
            if (dependent.selected) {
                patientids.push({
                    patientid: dependent.patientID,
                    memberId: dependent.memberID,
                });
                patientDetails[dependent.patientID] = {
                    memberUsername: dependent.memberUsername,
                };
            }
        });

        setPatientIds(patientids);
        return patientids;
    };

    const getAppointments = (force = false, dependents) => {
        let _ids = patientIds;
        if (dependents && dependents.length) {
            _ids = _getPatientIds({
                dependents,
                loginMember: _memberDetails.loginMember,
            });
        }
        let dateTime = new Date();
        let dateFormat = dateTime.getMonth()+1 + "/" +
        dateTime.getDate() + "/" + dateTime.getFullYear() + " " +
        ("0" + dateTime.getHours()).slice(-2) + ":" +
        ("0" + dateTime.getMinutes()).slice(-2) + ":" +
        ("0" + dateTime.getSeconds()).slice(-2);

        let data = {
            memberDetailsList: _ids,
            loginMemberID: _memberDetails.loginMember[0].memberID,
            clientDate: dateFormat,
        };

        if (timer) {
            clearTimeout(timer);
        }
        // timer = setTimeout(() => {
        if (patientIds.length) {
            setLoading(true);

            GetAllAppointments(data)
                .then((res) => {
                    checkVersionDiff(dispatch, res);
                    let temp = false;
                    setHasAppointments(res.data.totalCount);
                    setTotalCount(res.data.totalCount);
                    if (
                        res.data &&
                        res.data.data &&
                        res.data.data.upcomingAppointment &&
                        res.data.data.upcomingAppointment.length
                    ) {
                        let upcomingAppointments = [];
                        let ua = res.data.data.upcomingAppointment;
                        ua.forEach((a) => {
                            upcomingAppointments.push(...a.appointments);
                        });
                        upcomingAppointments = upcomingAppointments.sort(
                            (a1, a2) => {
                                let d1 = new Date(a1.date + ' ' + a1.starttime);
                                let d2 = new Date(a2.date + ' ' + a2.starttime);
                                let t1 = (d1 && d1.getTime()) || 0;
                                let t2 = (d2 && d2.getTime()) || 0;
                                return t1 - t2;
                            }
                        );
                        upcomingAppointments.length &&
                            setUpcomingAppointmentDate(
                                upcomingAppointments[0].date +
                                    ' ' +
                                    upcomingAppointments[0].starttime
                            );
                        dispatch(
                            storeUpcomingAppointments(upcomingAppointments)
                        );
                        temp = true;
                    }
                    if (
                        res.data &&
                        res.data.data &&
                        res.data.data.pendingAppointmentRequestDetails &&
                        res.data.data.pendingAppointmentRequestDetails.length
                    ) {
                        let pendingAppointments = [];
                        let ua = res.data.data.pendingAppointmentRequestDetails;
                        ua.forEach((a) => {
                            pendingAppointments.push(
                                ...a.appointmentRequestDetail
                            );
                        });
                        pendingAppointments = pendingAppointments.sort(
                            (a1, a2) => {
                                let d1 = new Date(a1.appointmentRequestDate);
                                let d2 = new Date(a2.appointmentRequestDate);
                                let t1 = (d1 && d1.getTime()) || 0;
                                let t2 = (d2 && d2.getTime()) || 0;
                                return t1 - t2;
                            }
                        );
                        // dispatch(storePendingAppointments(pendingAppointments));
                        temp = false;
                    }
                })
                .catch((e)=>{
                    storeUpcomingAppointments([]);
                    console.log(e);
                })
                .finally(() => {
                    setLoading(false);
                    hideLoader();
                });
        } else if (
            upcomingAppointments?.length ||
            pendingAppointments?.length
        ) {
            setHasAppointments(true);
        }
        // }, 0);
    };
    /**
     * Getting the appointments for all loggedin member and dependents, sending all these details to the store
     */
    useEffect(() => {
        // let data = {
        // 	"practiceid": 195900,
        // 	"patientid": [{
        // 		"patientid":1
        // 	}]
        // }
        getAppointments();
        let messageData = {
            params: 
                {
                    status: 'Inbox',
                },
        };
        GetUnReadMessageCount(messageData)
                .then((res) => {
                    checkVersionDiff(dispatch, res);
                    res.data?.count
                        ? setMessagesCount(res.data.count)
                        : setMessagesCount();
                })
                .catch((err) => {});
        let LabResultsData = {
            memberIds: isImpersonating ? [user.id] : [memberDetails?.loginMember[0]?.memberID],
        };
        GetUnReadLabResultCount(LabResultsData)
                .then((res) => {
                    checkVersionDiff(dispatch, res);
                    res.data?.totalCount
                        ? setLabResultCount(res.data.totalCount)
                        : setLabResultCount(0);
                    setLabResultCountLoading(false)
                })
                .catch((err) => {});
    }, [patientIds, isImpersonating, user]);

    /**
     * Getting the bills, messages and lab result unread count
     */

    useEffect(() => {
        let memberIds = [
            {
                memberID: memberDetails?.loginMember[0]?.memberID,
            },
        ];
        let memberIdsForLabCount = [memberDetails?.loginMember[0]?.memberID]
        memberDetails?.dependents?.map((it) => {
            if (it.selected === true) {
                memberIds.push({ memberID: it.memberID });
                memberIdsForLabCount.push(it.memberID)
            }
        });
        let LabResultsData = {
            memberIds: memberIdsForLabCount,
        };
        let billsData = {
            multiMemberIdRequest: memberIds,
        };
        let messageData = {
            params: 
                {
                    status: 'Inbox',
                },
        };
        if (isAvailable) {
            setLabResultCountLoading(true)
            GetUnReadLabResultCount(LabResultsData)
                .then((res) => {
                    checkVersionDiff(dispatch, res);
                    res.data?.totalCount
                        ? setLabResultCount(res.data.totalCount)
                        : setLabResultCount(0);
                    setLabResultCountLoading(false)
                })
                .catch((err) => {});
            GetUnReadMessageCount(messageData)
                .then((res) => {
                    checkVersionDiff(dispatch, res);
                    res.data?.count
                        ? setMessagesCount(res.data.count)
                        : setMessagesCount();
                })
                .catch((err) => {});
        }
    }, [isAvailable, memberDetails]);
    const toggle = () => {
        setOpen(!open)
    }
    const callToggle = () => {
        setOpenContacts(!openContacts)
    }
    return (
        <div className="Home margin-top-large">
            <Container className="solis-home">
                {/* Breadcrumb */}
                <BreadCrumb onRefreshAppointments={getAppointments} />
                <Row className="portfolio">
                    <Col className="mx-auto row">
                        <div className="immediate-care">
                            <div style={{width: '100%', textAlign:'center'}}>
                                <h1>Immediate Care</h1>
                                <p>Available 24/7</p>
                            </div>
                            
                            
                            {/*<button className={`button-action btn-border ${!isOnline ? `btn-disable` : ""}`} onClick={toggle}>
                                <i className="fa-light fa-message-lines"></i>
                            </button> */}
                            <button style={{width:'100%', maxWidth: '20em'}} className={`button-action ${!isOnline ? `btn-disable` : ""}`} onClick={callToggle}>
                                <i className="fa-light fa-phone"></i>
                            </button>
                            

                        </div>
                    </Col>
                </Row>
                <Row className="row whitebg appointment">
                    <Col
                        lg="6"
                        className={`appointment-label ${
                            !isOnline ? `text-disable` : ''
                        }`}
                        onClick={appointmenttype}
                    >
                        <i className="icon fa-light fa-calendar-circle-plus" />
                        <p>Schedule Appointment</p>
                        <i className="fa-light fa-chevron-right" />
                    </Col>
                    <Col lg="6" className="appointment-label" onClick={viewAppointments}>
                        {hasAppointments ? (
                            <Link to="/scheduleAppointments">
                                <i className="icon fa-light fa-calendar-check" />
                                <p>
                                    Appointments{' '}
                                    {/* {totalAppointments == 1 ? (
                                        ''
                                    ) : totalAppointments ? (
                                        <span className="count">
                                            {totalAppointments}
                                        </span>
                                    ) : (
                                        ''
                                    )} */}
                                    {totalCount ? (
                                        <span className="count">
                                            {totalCount}
                                        </span>
                                    ) : (
                                        ''
                                    )}
                                </p>
                                {loading ? (
                                    <div className="working appointmentsloader"></div>
                                ) : (
                                    <div className="block">
                                        {hasAppointments ? (
                                            <span className="date">
                                                {upcomingAppointmentDate
                                                    ? moment(
                                                          upcomingAppointmentDate
                                                      ).format(
                                                          'ddd, MMMM Do LT'
                                                      )
                                                    : ''}{' '}
                                                {/* {upComingAppointmentTimeZone && (
                                                    <span className="date">
                                                        {
                                                            upComingAppointmentTimeZone
                                                        }
                                                    </span>
                                                )} */}
                                            </span>
                                        ) : (
                                            'No upcoming appointments'
                                        )}
                                    </div>
                                )}
                                <i className="fa-light fa-chevron-right" />
                            </Link>
                        ) : (
                            <div className="noAppointments">
                                <Link to="/scheduleAppointments">
                                    <i className="icon fa-light fa-calendar-check" />
                                    <p>Appointments</p>
                                    {loading ? (
                                        <div className="working appointmentsloader"></div>
                                    ) : (
                                        <div className="appointmentsloader apt-color">
                                            No upcoming appointments
                                        </div>
                                    )}
                                </Link>
                            </div>
                        )}
                    </Col>
                    <Col
                        lg="6"
                        className="appointment-label"
                        onClick={messages}
                    >
                        <i className="icon fa-light fa-envelope-open-text" />
                        <p>
                            Messages{' '}
                            {messagesCount ? (
                                <span className="count">{messagesCount}</span>
                            ) : (
                                ''
                            )}
                        </p>

                        <i className="fa-light fa-chevron-right" />
                    </Col>
                    <Col
                        lg="6"
                        className="appointment-label"
                        onClick={routeChange}
                    >
                        <i className="icon fa-light fa-hospital-user" />
                        <p>
                            Test Results{' '}
                            {labResultCountLoading ?  (
                                    <div className="working appointmentsloader"></div>
                                ) : typeof labResultCount == 'number' && labResultCount > 0 ? (
                                <span className="count">{labResultCount}</span>
                            ) : ('')}
                        </p>
                        <i className="fa-light fa-chevron-right" />
                    </Col>

                    <Col
                        lg="6"
                        className="appointment-label"
                        onClick={SpecialistReferralList}
                    >
                        <i className="icon fa-light fa-user-doctor" />
                        <p>Specialist Referral</p>
                        {greyOutAreas && <i className='fa-light fa-chevron-right' />}
                    </Col>
                    <DocumentCenterButton />
                    {/* 
                    <Col
                        lg="6"
                        className={`appointment-label disable ${
                            !isOnline ? `text-disable` : ''
                        }`}
                        onClick={greyOutAreas ? billing : undefined}
                    >
                        <i className="icon fa-light fa-file-invoice-dollar" />
                        <p>
                            Billing{' '}
                            {greyOutAreas && billCount ? (
                                <span className="count">{billCount}</span>
                            ) : (
                                ''
                            )}
                        </p>
                        {greyOutAreas && <i className="fa-light fa-chevron-right" />}
                    </Col> */}
                </Row>
                <div className='footer-call'>
                    <FooterCompliance />
                </div>
                
            </Container>
            <ContactModal modal={openContacts} toggle={callToggle} />
            <MessageModal modal={open} toggle={toggle} />
            
        </div>
    );
}

export default Home;
