import { Row, Col } from 'reactstrap';
import { useOnlineStatus } from '../utilities/useOnlineStatus';


function FooterCompliance() {
    const isOnline = useOnlineStatus(); //network connection status

    return (
        <Row>
            <Col>
                <a
                    href="https://sollishealth.com/privacy/"
                    target="_blank"
                    rel="noreferrer"
                    className={isOnline ? '' : 'text-disable'}
                >
                    Privacy Policy{' '}
                </a>
            </Col>
        </Row>
    );
}

export default FooterCompliance;
